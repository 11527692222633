<template>
    <client-page>

        <sub-visual sh="해외사업" tabActive="긴급구호" bg="/images/sub/visual/sv2.jpg" />

        <section class="section">
            <v-container>

                <div class="mb-20 mb-lg-40">
                    <p class="page-text page-text--lg grey-1--text line-height-15 mt-8 mt-lg-16">
                        지진·홍수·가뭄 등의 자연재해나 지역 간 분쟁 등 긴급상황이 발생한 지역에 구호단을 파견하여 구호물품을 전달하고, 피해지역 복구를 위한 지원활동을 실시합니다.
                    </p>
                </div>

                <v-row no-gutters class="word-keep-all text-center border">
                    <v-col cols="6" md="3" class="border-bottom border-md-bottom-0 border-end">
                        <div class="pa-20 py-md-34 d-flex flex-column align-center">
                            <div class="step mb-14 mb-lg-20">
                                <p class="page-text pag-text--lg primary--text">
                                    STEP 1
                                </p>
                            </div>
                            <h6 class="page-text page-text--lg grey-1--text mb-4">
                                조사팀 파견&대응전략 수립
                            </h6>
                            <p class="page-text">
                                긴급구호 상황 발생 시 조사팀 현장 파견,
                                피해정도 및 필요 물품 파악
                            </p>
                        </div>
                    </v-col>
                    <v-col cols="6" md="3" class="border-bottom border-md-bottom-0 border-md-end">
                        <div class="pa-20 py-md-34 d-flex flex-column align-center">
                            <div class="step mb-14 mb-lg-20">
                                <p class="page-text pag-text--lg primary--text">
                                    STEP 2
                                </p>
                            </div>
                            <h6 class="page-text page-text--lg grey-1--text mb-4">
                                모금 및 구호품 모집
                            </h6>
                            <p class="page-text">
                                긴급 캠페인 진행, 온.오프라인을 통한
                                모금 및 구호품 모집 활동
                            </p>
                        </div>
                    </v-col>
                    <v-col cols="6" md="3" class="border-end">
                        <div class="pa-20 py-md-34 d-flex flex-column align-center">
                            <div class="step mb-14 mb-lg-20">
                                <p class="page-text pag-text--lg primary--text">
                                    STEP 3
                                </p>
                            </div>
                            <h6 class="page-text page-text--lg grey-1--text mb-4">
                                긴급구호 활동
                            </h6>
                            <p class="page-text">
                                현지전문가와 의료진으로 구성된
                                긴급구호팀 파견, 의료 및 구호물자 지원
                            </p>
                        </div>
                    </v-col>
                    <v-col cols="6" md="3">
                        <div class="pa-20 py-md-34 d-flex flex-column align-center">
                            <div class="step mb-14 mb-lg-20">
                                <p class="page-text pag-text--lg primary--text">
                                    STEP 4
                                </p>
                            </div>
                            <h6 class="page-text page-text--lg grey-1--text mb-4">
                                중장기 복구 / 재건 활동
                            </h6>
                            <p class="page-text">
                                재해재난으로 파괴된 시설 복구,
                                자립 프로그램 실시
                            </p>
                        </div>
                    </v-col>
                </v-row>

            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from '@/components/client/sub/sub-visual.vue';
export default {
    components: {
        ClientPage,
        SubVisual,
    },
};
</script>

<style scoped>
.step{
    width: 80px;
    height: 28px;
    border-radius: 999px;
    border: 1px solid var(--v-primary-base);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .step{
        width: 104px;
        height: 38px;
    }
}
@media (min-width:1200px){
}

</style>