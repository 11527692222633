var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "해외사업",
      "tabActive": "긴급구호",
      "bg": "/images/sub/visual/sv2.jpg"
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('div', {
    staticClass: "mb-20 mb-lg-40"
  }, [_c('p', {
    staticClass: "page-text page-text--lg grey-1--text line-height-15 mt-8 mt-lg-16"
  }, [_vm._v(" 지진·홍수·가뭄 등의 자연재해나 지역 간 분쟁 등 긴급상황이 발생한 지역에 구호단을 파견하여 구호물품을 전달하고, 피해지역 복구를 위한 지원활동을 실시합니다. ")])]), _c('v-row', {
    staticClass: "word-keep-all text-center border",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "border-bottom border-md-bottom-0 border-end",
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "pa-20 py-md-34 d-flex flex-column align-center"
  }, [_c('div', {
    staticClass: "step mb-14 mb-lg-20"
  }, [_c('p', {
    staticClass: "page-text pag-text--lg primary--text"
  }, [_vm._v(" STEP 1 ")])]), _c('h6', {
    staticClass: "page-text page-text--lg grey-1--text mb-4"
  }, [_vm._v(" 조사팀 파견&대응전략 수립 ")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 긴급구호 상황 발생 시 조사팀 현장 파견, 피해정도 및 필요 물품 파악 ")])])]), _c('v-col', {
    staticClass: "border-bottom border-md-bottom-0 border-md-end",
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "pa-20 py-md-34 d-flex flex-column align-center"
  }, [_c('div', {
    staticClass: "step mb-14 mb-lg-20"
  }, [_c('p', {
    staticClass: "page-text pag-text--lg primary--text"
  }, [_vm._v(" STEP 2 ")])]), _c('h6', {
    staticClass: "page-text page-text--lg grey-1--text mb-4"
  }, [_vm._v(" 모금 및 구호품 모집 ")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 긴급 캠페인 진행, 온.오프라인을 통한 모금 및 구호품 모집 활동 ")])])]), _c('v-col', {
    staticClass: "border-end",
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "pa-20 py-md-34 d-flex flex-column align-center"
  }, [_c('div', {
    staticClass: "step mb-14 mb-lg-20"
  }, [_c('p', {
    staticClass: "page-text pag-text--lg primary--text"
  }, [_vm._v(" STEP 3 ")])]), _c('h6', {
    staticClass: "page-text page-text--lg grey-1--text mb-4"
  }, [_vm._v(" 긴급구호 활동 ")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 현지전문가와 의료진으로 구성된 긴급구호팀 파견, 의료 및 구호물자 지원 ")])])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "pa-20 py-md-34 d-flex flex-column align-center"
  }, [_c('div', {
    staticClass: "step mb-14 mb-lg-20"
  }, [_c('p', {
    staticClass: "page-text pag-text--lg primary--text"
  }, [_vm._v(" STEP 4 ")])]), _c('h6', {
    staticClass: "page-text page-text--lg grey-1--text mb-4"
  }, [_vm._v(" 중장기 복구 / 재건 활동 ")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 재해재난으로 파괴된 시설 복구, 자립 프로그램 실시 ")])])])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }